import { useNavigate, Link } from 'react-router-dom';


export default function Privacy(){
    const navigate = useNavigate();

    return (
        <div className="legal">
            <h2>Terms of Use</h2>
            <p>Last updated: April 19th, 2023</p>
            <p>Thank you for visiting our website, mobile website, or mobile application (“app”) (collectively, the "Platform"). </p>
            <p>Labatt Brewing Company Limited, Anheuser-Busch InBev and our subsidiary and affiliated entities, which include different brands, (“we”, “us” or “our”) value and respect your privacy. This Privacy Policy describes how our brands collect, use, disclose and protect information when you visit or use our Platform, or otherwise interact with us. </p>
            <p>A list of our brands that are responsible for the collection, use and disclosure of personal information subject to this Privacy Policy can be found here.</p>
            <h4>Intended for Adults</h4>
            <p>The Platform is not directed to, and we do not knowingly collect personal information from, persons under the age of majority in their jurisdiction of residence.  If you are under the age of majority and have entered this Platform by providing a false date of birth, you must immediately leave this Platform.  <strong>Under no circumstances may you use this Platform or submit personal information to us if you are under the age of majority. </strong></p>
            <p>Collection and Use of Personal Information</p>
            <p>Below are examples of how we collect and use personal information on our Platform.</p>
            <ul>
                <li><strong>Visit our Platform:</strong> In order to access our Platform, we may require you to enter your date of birth and jurisdiction of residence to verify that you are the age of majority in your jurisdiction of residence in order to meet regulatory requirements.</li>
                <li><strong>Create an Account:</strong> To access and use our Platform, you may be required to create an account. When you create an account, we may collect personal information such as your first and last name, email address, birth date, gender (optional) and a password that you create. We use this information to create and administer your account and to provide the products and services you request. We strongly recommend that you do not disclose your password to anyone. We will never ask you for your password in any unsolicited communication (such as letters, phone calls or email messages). If you become aware of any unauthorized access to or use of your account, you are required to notify us immediately.</li>
                <li><strong>Purchase Products and Services:</strong> If you are making a purchase on the Platform, we (or our authorized third-party payment processor) may collect payment information (including billing address, credit card number, expiry data and CVV code) in order to process the transaction. In such event, it is our policy to redirect you to a payment processing portal hosted by a third-party payment processor.   Please read the privacy policy on our payment processor’s website regarding the use, storage and protection of your credit card information before submitting any payment information.</li>
                <li><strong>Marketing:</strong> If you choose to sign-up for or engage with our marketing communications, we may send you special offers, e-blasts, and information about other services that we believe may be of interest to you. We will collect certain information such as your name, date of birth (to verify your age), postal code (to verify your jurisdiction of residence) and email address. If you no longer wish to receive these communications, you may unsubscribe any time by clicking the “unsubscribe” link at the bottom of the email or by contacting us at the contact information under “Contact Us” below. Please note that you may continue to receive certain transactional or account-related communications from us.</li>
            </ul>
            <p>If you engage with any organic content, sponsored content and advertising content  on social and digital channels such as engaging with an influencer’s post, “liking” our posts, or watching content we’ve posted, we may collect information (e.g. likes, shares, and comments) for the purposes of evaluating the effectiveness of our marketing campaign, targeting future content in more relevant ways, or improving future marketing efforts.</p>
            <p>We may also use services provided by third-party platforms (such as social media and other websites) to serve targeted advertisements on such platforms to you or others, and we may provide a hashed version of your email address or other information to the platform provider for such purposes. To opt-out of the use of your information for such purposes, please send a request using any of the methods set forth in the Contact Us section below.</p>
            <ul>
                <li><strong>Location Information:</strong> With your consent, we may collect your location information by accessing your device’s GPS coordinates so that we can provide you with location-based services. You can opt-out of location tracking at any time by turning off your location services for the Platform in the settings of your device.</li>
                <li><strong>Push Notifications:</strong> If you provide your consent to receive push notifications, we may display push notifications on your mobile device such as welcome messages, product features and promotional offers. If you wish to stop receiving push notifications from us, you can turn off push notifications for the Platform in the settings of your device.</li>
                <li><strong>Contests, Promotions and Redemptions:</strong> When you enter a contest or participate in a promotion, we may collect your name, date of birth, address, phone number, email address, and other information you provide. We use this information to administer your participation in the contest or promotion and fulfil your prize. We may collect this information through our Platform or different devices, such as a tablet or other mobile device, including at events or venues as part of an on-site promotion or offline using forms or paper entries.<br/>When you purchase a product or service you may also be provided with a redemption code that you can enter on our website or mobile website along with other information such as your name and email address to redeem promotional merchandise or services.<br/>In addition to using this information for your participation in a contest or promotion, we may also use it for the purposes of evaluating the effectiveness of a contest or promotion, targeting future marketing content in more relevant ways, or improving future marketing efforts.</li>
                <li><strong>Contact Us:</strong> When you contact us with a comment or question through email, telephone, or the Contact Us form on our Platform, you may provide information that identifies you, such as your name, email, address, birth date or telephone number, along with additional information we need to help us promptly respond. We may retain this information to assist you in the future and to improve our products and services and to customize your experience with us, including to help us diagnose problems with our server or other technical issues relating to the performance of the Platform.</li>
                <li><strong>Business Partners:</strong> We may collect information about you from third parties such as from our business partners. We may use this information to conduct research and analysis, improve our marketing efforts, inform product development and innovation, and for supply and logistics improvements. We and our business partners may combine information that we have collected offline with information we collect online and across different devices over time.</li>
                <li><strong>Community Forums/Public Spaces:</strong> We may collect information you post on a public space such as a community forum on the Platform.  <strong>Note about third-party uses: If you voluntarily disclose personal information (e.g., name, email address, personal photographs) in a community forum, such information may be collected and used by others and may result in unsolicited messages from other parties.  We have no control over this type use of your personal information and are not responsible for that use. Please exercise caution before sharing any personal information.</strong></li>
                <li><strong>Careers:</strong> If you apply for a job with us, you may provide us with certain personal information about yourself, such as information contained in a resume, cover letter, or similar employment-related materials. We use this information for the purpose of processing and responding to your application for current and future career opportunities.</li>
                <li>For any other purpose as otherwise required or permitted by law or as stated at the time you provide your information.</li>
            </ul>
            <h4>INFORMATION ABOUT OUR PLATFORM</h4>
            <ul>
                <li><strong>Visiting our Platform:</strong> In general, you can visit our Platform without telling us who you are or submitting any personal information. However, we collect the IP (Internet protocol) addresses of all visitors to our Platform as well as other related information such as page requests, browser type, device identifier (if you are using our mobile app), operating system and average time spent on our Platform. We use this information to help us understand our Platform activity and to monitor and improve our Platform.</li>
                <li><strong>App Usage Information & Analytics:</strong> As with many apps, certain limited data is required for the app to function on your device. This data includes the type of device hardware and operating system, unique device identiﬁer, IP address, language settings, and the date and time the app accesses our servers. We use this information to help us understand the activity on our app, to monitor and improve our app, and to tailor your in-app experience. In addition, we may use third party service providers to collect analytical information about your use of the app, such as the app features used and time spent on the app, to help us tailor your in-app experience, improve our products and the quality of our app, and to manage and analyze data in order to better understand our users.</li>
                <li><strong>Cookies:</strong>  We use certain standard web measurement and tracking technologies such as "cookies," web server logs, web beacons (also known as "action tags" or "one pixel .gif files"), clear GIFs or other statistics programs.  A “cookie” is a tiny element of data that our website sends to a user’s browser, which may then be stored on the user’s hard drive so that we can recognize the user’s computer or device when they return.  We do this on our Platform (including mobile apps) and in emails that we send to you. We collect information about users over time when you use this Platform (including mobile apps). We have third parties collect information this way, as well. Your browser may have an option that allows you to accept cookies, reject cookies, or receive notification when a cookie is sent, but you should note that the use of restrictive browser settings may limit your use of the Platform.</li>
                <li><strong>Analytics:</strong> Our website uses web analytics services such as Adobe Analytics and Google Analytics. Web analytics services use cookies to help us analyze how visitors use the website. Analytics platforms work by using the information generated by a cookie about your use of the website (including your IP address) for the purpose of evaluating your use of the website, compiling reports on website activity for website operators, and providing website operators with other services relating to website activity and internet usage. See the “Cookies” section above to learn more about how you can opt-out of analytics cookies.</li>
            </ul>
            <p>How We Share Your Information</p>
            <p>We do not sell, rent or disclose your personal information to third parties without your consent, except as described below or as required or permitted by applicable law.</p>
            <ul>
                <li><strong>Service Providers:</strong> Your personal information may be transferred (or otherwise made available) to third parties that provide services on our behalf. We use service providers to provide services such as hosting a website, processing payments, administering promotions and contests, providing emailing and advertising and marketing services. Our service providers are only provided with the information they need to perform their designated functions and are not authorized to use or disclose personal information for their own marketing or other purposes. Our service providers may be located in the U.S., Canada or other foreign jurisdictions.</li>
                <li><strong>Business Partners:</strong> With your consent, we may provide your personal information to our affiliated companies, co-sponsors or other business partners to use in accordance with their own privacy policies. We may share your personal information so that our business partners, affiliated companies or co-sponsors may provide you with information on the products and services they make available to you through our Platform or that may be of interest to you.  We share information within the Anheuser-Busch InBev family of brands.</li>
                <li><strong>Legal and Compliance:</strong> We and our Canadian, US and other foreign affiliates and service providers may disclose your personal information in response to a search warrant or other legally valid inquiry or order, or to another organization for the purposes of investigating a breach of an agreement or contravention of law or detecting, suppressing or preventing fraud, or as otherwise may be required or permitted by applicable Canadian, U.S. or other law or legal process, which may include lawful access by U.S. or foreign courts, law enforcement or other government authorities. Your personal information may also be disclosed where necessary for the establishment, exercise or defence of legal claims and to investigate or prevent actual or suspected loss or harm to persons or property.</li>
                <li><strong>Sale of Business:</strong> We may transfer any information we have about you as an asset in connection with a proposed or completed merger, acquisition or sale (including transfers made as part of insolvency or bankruptcy proceedings) involving all or part of our business or as part of a corporate reorganization or other change in corporate control.</li>
            </ul>
            <p>Where required or permitted by applicable law, we may share information for other reasons we disclose to you.</p>
            <p>Your Choices</p>
            <h4>You Have Certain Choices About How We Use Your Information</h4>
            <h4>You Can Control Tracking Tools and Third-Party Advertising</h4>
            <p>We may use third-party companies and/or media partners that use their own tracking technologies (including cookies and pixel tags) to serve ads on our Platform and/or on the sites on which we advertise.  We and these third-party companies display interest-based advertising using information gathered about you over time across multiple or other platforms. In the course of serving advertisements, these companies may place or recognize a unique cookie on your browser. In addition, we may use web beacons provided by third-party advertising companies to help manage our online advertising.  These web beacons enable us to recognize a browser’s cookie when a browser visits this Platform to learn which advertisements bring users to our Platform. These companies may collect information about your activity on our Platform and third-party websites (such as web pages you visit and your interaction with our advertising and other communications) and use this information to make predictions about your preferences, develop personalized content and deliver ads that are more relevant to you on third party websites. This information may also be used to evaluate the effectiveness of our online advertising campaigns.</p>
            <p>To learn more about your choices and how to opt out of this type of advertising, please visit the Cookie Settings hyperlink available on our Platform and utilize the choices and settings available through your device or browser. Alternatively you can email us at crg@labatt.com with any questions about your cookie preferences. </p>
            <p>These choices will apply to data collected from other browsers or devices for use on this device for interest-based advertising and other applicable uses.  Certain choices you make may be browser-specific and/or device-specific. Please note that even if you opt-out of interest-based advertising by a third party, these tracking technologies may still collect data for other purposes including analytics and you will still see ads from us, but the ads will not be targeted based on information about you and may therefore be less relevant to you and your interests.</p>
            <p>To successfully opt-out, you must have cookies enabled in your web browser (see your browser’s instructions for information on cookies and how to enable them). Your opt-out only applies to the web browser you use so you must opt-out of each web browser on each computer you use. Once you opt-out, if you delete your browser’s saved cookies, you will need to opt-out again.</p>
            <h4>Opting Out of Receiving our Emails</h4>
            <p>To stop receiving our marketing emails, email us at crg@labatt.com or click on the link provided at the bottom of each email communication. Even if you opt-out of receiving marketing communications, we will still send you messages about your questions and purchases.</p>
            <h4>You can control tools on your mobile devices</h4>
            <p>For example, you can turn off push notifications from within a mobile app.</p>
            <p>Linked Sites</p>
            <p>Our Platform may contain links to other websites, including social media sites, which may have privacy policies that differ from our own.  We are not responsible for the collection, use or disclosure of information collected through third-party websites and expressly disclaim any liability related to such collection, use or disclosure.  We are not responsible for any information or content contained on such sites.  Links to other websites are provided solely as a convenience. Your browsing, use and interaction on any other websites, including websites which have a link to the Platform, are subject to that website’s own rules and policies.  Please review the privacy policy posted on any website that you may access through, or which links to the Platform.</p>
            <p>Please note that <strong>if you provide information to us via a social media site or participate in a social media site linked to the Platform, you consent to our use of your information for any reason as if it were submitted to us directly via the Platform.</strong></p>
            <p>Security Procedures and Retention</p>
            <p>We have implemented reasonable administrative, technical and physical measures in an effort to safeguard the personal information in our custody and control against theft, loss and unauthorized access, use, modification and disclosure. We restrict access to personal information on a need-to-know basis to employees and authorized service providers who require access to fulfil their job requirements.</p>
            <p>We have record retention processes designed to retain personal information for no longer than necessary for the purposes set out herein or as otherwise required to meet legal or business requirements.</p>
            <p>Changes and Updates to the Privacy Policy</p>
            <p>From time to time, we may revise the Privacy Policy.  To help you stay current of any changes, we may take one or more of the following steps: (1) we note the date the Privacy Policy was last updated above, and (2) when we make a material change to the Privacy Policy, we may post announcements of such changes on the homepage of the Platform next to the link to this Privacy Policy.  Your use of the Platform following the posting of any revised Privacy Policy shall be deemed acceptance of the revised policy, so we strongly recommend that you review the Privacy Policy periodically.  If we are required by law to obtain your express consent for any changes to the Privacy Policy, then we will make a commercially reasonable attempt to obtain your consent before implementing such revisions.</p>
            <p>Mobile Applications</p>
            <p>From time to time, we may offer certain mobile apps, including iOS applications ("Apps"), for download.  We will use commercially reasonable efforts, given the limitations imposed upon us by third party providers such as Apple Inc. and Google Inc., to clearly disclose what, if any information is collected by the particular App, how it is used, and with whom it is shared.  <strong>Please note, certain practices are outside of our control, for example, tracking by Google, Inc., Apple, Inc., or your third party telecommunications carrier.  We are not responsible for the actions of third parties.  You should always read and understand the policies of any third party provider, such as Google with respect to Android apps and Apple with respect to IOS apps, and your wireless carrier, before making any purchase or downloading any app.</strong></p>
            <p>Contact Us</p>
            <p>If you have any questions or comments about this Privacy Policy or the manner in which we or our service providers treat your personal information, or to request access to your personal information in our records, please contact us by mail or email at:</p>
            <p><strong>Attn: Labatt Privacy Officer</strong><br/>
Labatt Brewing Company Limited<br/>
207 Queens Quay W, Suite 299, <br/>
Toronto, ON M5J 1A7</p>
            <p>privacy.officer@labatt.com</p>
            <h4>Labatt Brand Families</h4>
            <p>Please visit www.labattbrands.com for a list of Labatt Brand Families.</p>
        </div>
    );
}