import { useNavigate } from 'react-router-dom';
import { Tween } from 'react-gsap';
import { getSessionProperty } from '../Model';

import sixforsixlock from '../../assets/646 Lockup.png';

export default function Confirm({}){
    const navigate = useNavigate();
    const timeslot = getSessionProperty('timeslot');

    return (
        <Tween from={{ y:50, alpha:0, stagger:.2 }}>
        <h1><img src={sixforsixlock} className="six-lock" alt="The Six Flavour Swap logo over 646 spelled out numerically." /></h1>
        <h2>THANK YOU</h2>
        <p>Your Six Flavour Swap is booked for:</p>
        <div className="date-box mb-2">
            <p>{timeslot.split('|')[0]}<br/>{timeslot.split('|')[1]}</p>
        </div>
        <p className="narrow">We’ll text you to remind you of your scheduled time. Please make sure you’re present for your swap with a valid ID
and your unwanted cans ready to go. Missed swaps cannot be rescheduled.</p>
        </Tween>
    );
}