import axios from 'axios';

let api = '';
// let apiPath = '';
let apiPostfix = '';

// let localsettings = '';

let session;
let timeslots;

export async function init(_apiPath,_apiPostfix){
    // console.log('init');
    api = axios.create({ baseURL: _apiPath });
    // apiPath = _apiPath;
    apiPostfix = _apiPostfix;

    await getAvailableSlots();
    // await getTimeAsync();
    return true;
}

export async function getAvailableSlots(forcerefresh){

    if (!forcerefresh && timeslots) {
        return timeslots;
    }

    const { data:result } = await api.get('timeslots'+apiPostfix);
    console.log(result.status,result.timeslots.length);
    if (result.status===200){
        timeslots = result.timeslots;
        return timeslots;
    } else {
        return [];
    }
}

export async function confirmEntry(id){
    const { data:result } = await api.post('confirm'+apiPostfix,{
        id:id
    });
    return result;
}
export async function submitEntry(submission){
    const { data:result } = await api.post('timeslot'+apiPostfix, submission);
    console.log(result);
    if (result.status===200){
        session = result.entry;
    }
    return (result);
    // return {status:200}; //result;
}
export function resetSession(){
    // session = null;
}
export function getSessionProperty(prop){
    return session[prop];
}

// todo. error handling.