import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tween } from 'react-gsap';
import { getAvailableSlots } from '../Model';

import sixforsixlock from '../../assets/646 Lockup.png';
import cans from '../../assets/Product.png';
import step1 from '../../assets/step 1.png';
import step2 from '../../assets/step 2.png';
import step3 from '../../assets/step 3.png';
import step4 from '../../assets/step 4.png';
import mixpack from '../../assets/MixPack.png';

export default function Landing({}){
    const navigate = useNavigate();

    function goForm(){
        navigate('/form');
    }

    useEffect( ()=>{
        async function getSlots(){
            const slots = await getAvailableSlots(); // how to ensure this doesn't get called before api is initialized?
            if (slots.length===0){
                navigate('/closed');
            }
        }
        getSlots();
    },[])

    return (
        <Tween from={{ y:50, alpha:0, stagger:.2 }}>
        <h1><img src={sixforsixlock} className="six-lock" alt="The Six Flavour Swap logo over 646 spelled out numerically." /></h1>
        <p>You know those cans that have been sitting in the back of your fridge for ages? We’ll replace up to 6 of them with 6 delicious flavours* of NÜTRL Vodka Soda from our <a href="https://www.lcbo.com/en/nutrl-the-mixed-12pk-24407" target="_blank">Mixed Pack</a> to help you with your spring cleaning.</p>
        <p>We’ll accept any flavour from that pumpkin spice seltzer you bought last fall to that craft beer your friend left at your place in 2018.</p>
        <button onClick={goForm}>Book a time</button>
        <img src={cans} className="cans mb-6" alt="Six cans of NÜTRL, each one of them a different flavour." />
        
        <h2>TRADE IN THE OLD FOR THE NÜ</h2>
        <div className="steps-row">
            <div className="steps-cell">
                <h4>Step 1:</h4>
                <p>Book a pickup time.</p>
                <img src={step1} alt="Clock icon." />
            </div>
            <div className="steps-cell">
                <h4>Step 2:</h4>
                <p>Our Swap Squad will meet you at your door.</p>
                <img src={step2} alt="Moving van icon." />
            </div>
            <div className="steps-cell">
                <h4>Step 3:</h4>
                <p>Give us up to 6 of your unwanted flavours.</p>
                <img src={step3} alt="An icon depicting 3 different cans." />
            </div>
            <div className="steps-cell">
                <h4>Step 4:</h4>
                <p>We’ll give you 6 refreshing NÜTRL Vodka Soda flavours.</p>
                <img src={step4} alt="An icon showing a can turn into a NÜTRL can." />
            </div>
        </div>
        <button onClick={goForm}>Book a time</button>
        <p className="mb-2">Want to upgrade your flavours but don’t have any to swap? The NÜTRL Vodka Soda Mixed Pack has 6 flavours in 1 convenient 12 pack including peach, lime, grapefruit, lemon, cranberry, and mango. Available at the <a href="https://www.lcbo.com/en/nutrl-the-mixed-12pk-24407" target="_blank">LCBO</a>.</p>
        <img src={mixpack} className="mixpack" alt="The NÜTRL Mixed Pack with 6 cans on the front." />
        </Tween>
    );
}