import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

export default function Footer(){
    const location = useLocation();
    
    const [ isLanding, setIsLanding ] = useState(false);
    const [ isForm, setIsForm ] = useState(false);
    const [ isSplash, setIsSplash ] = useState(false);

    // setIsSplash();
    useEffect(()=>{
        const path = location.pathname;
        // console.log(location.pathname);
        setIsSplash( path==='/' );
        setIsLanding( path==='/landing' );
        setIsForm( path==='/form' );
    },[location])

    return (
        <footer className={isSplash?'':'dark'}>
            <div className="pre-footer">
                {isLanding && <span>*355ml cans</span>}
                {isForm && <span>*Limit of 20 spots per time slot. Availability is not guaranteed.</span>}
            </div>
            <div className="footer-row">
                <Link to="/terms">Terms of Use</Link>
                <Link to="/privacy">Privacy Policy</Link>
                <Link to="mailto:hello@nutrlvodka.com">Contact Us</Link>
            </div>
            <div>
            Enjoy responsibly. Must be legal drinking age. ®/MD G&amp;W DISTILLING INC.
            {isSplash && <>
                <br/>
                By submitting this form, you agree to be bound by the <Link to="/terms">Terms of Use</Link> and <Link to="/privacy">Privacy Policy</Link>.
            </>}
            </div>
        </footer>
    );
}