import { useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import { Tween } from 'react-gsap';
import { submitEntry, getAvailableSlots } from '../Model';

import sixforsixlock from '../../assets/646 Lockup.png';

const fields = [
    {label:'First name',type:'text',id:'firstname',required:true},
    {label:'Last name',type:'text',id:'lastname',required:true},
    {label:'Street number',type:'text',id:'streetno',required:true},
    {label:'Street name',type:'text',id:'streetname',required:true},
    {label:'Unit, Suite, Apt. etc.',type:'text',id:'unit'},
    {label:'City',type:'text',id:'city',required:true,value:'Toronto'},
    {label:'Province',type:'text',id:'province',required:true,value:'Ontario'},
    {label:'Postal code',type:'text',id:'postal',required:true},
    {label:'Email',type:'email',id:'email',required:true},
    {label:'Phone number',type:'text',id:'phone',required:true},
// ]
// const datefields = [
    {label:'MM',type:'number',id:'bdaymm',min:1,max:12,required:true},
    {label:'DD',type:'number',id:'bdaydd',min:1,max:31,required:true},
    {label:'YYYY',type:'number',id:'bdayyy',min:1900,max:2005,required:true},
// optins
    {label:'I confirm I have read, understand, and agree to the <a href="/terms" target="_blank">Terms of Use</a> and <a href="/privacy" target="_blank">Privacy Policy</a>.',type:'checkbox',id:'optin1',required:true,errorMessage:"Please confirm you agree to the Terms of Use and Privacy Policy."},
    {label:'By entering your email, you are signing up to receive marketing messages from NÜTRL Vodka Soda and other trusted affiliates. You can withdraw your consent at any time. Please <a href="mailto:xxx.xxx.com">Contact Us</a> or refer to our <a href="/privacy" target="_blank">Privacy Policy</a> for more details.',type:'checkbox',id:'optin2',required:true,errorMessage:"Please confirm your consent to receive email updates."},
    {label:'timeslot',type:'hidden',id:'timeslot',required:true}
]

const GTA = 'M4B,M4C,M4G,M4P,M4T,M4W,M4Y,M5A,M5B,M5C,M5G,M5H,M5K,M5L,M5R,M5T,M5V,M5W,M5X,M6J,M6K,M6L,M6P,M6R,M7A,M7Y,M9M,M9N';
const validpostals = GTA.split(',');

const dayslots = [ "Sunday, April 23", "Saturday, April 29", "Sunday, April 30" ];
const timeslots = [ "9:00 AM-12:00 PM", "12:00 PM-3:00 PM", "3:00 PM-6:00 PM" ];

export default function Form({}){
    const navigate = useNavigate();

    const [ values, setValues ] = useState({
        firstname:'', lastname:'', streetno:'', streetname:'',
        unit:'', city:'Toronto', province:'Ontario', postal:'',
        email:'',phone:'',bdaydd:'',bdaymm:'',bdayyy:'',optin1:false, optin2:false
    })
    const [ freeSlots, setFreeSlots ] = useState([]);
    const [ errors, setErrors ] = useState({});
    const [ waiting, setWaiting ] = useState(false);
    const submitBtn = useRef(null);

    const handleTimeSlotSelect = e => {
        const val = { ...values };
        const err = { ...errors };
        delete err.timeslot;
        delete err.timeslottaken;

        val.timeslot = e.target.id;

        setValues(val);
        setErrors(err);
    }

    const handleInputChange = input => e => {
        submitBtn.current.disabled = false;
        
        const val = { ...values };
        const err = { ...errors };
        if (input==='city'||input==='province'){
            // no effect
        } else if (e.target.type==='checkbox'){
            val[input] = e.target.checked;
        } else if (input==='phone'){
            let nval = e.target.value;
            if (nval.length===3 || nval.length===7) { 
                console.log('add');
                nval += '-';
            } else if (nval.length===4 || nval.length===8){
                const arr = nval.split('');
                const post = arr.pop();
                const pre = arr.join('');
                console.log(pre,post);
                if (post==='-'){
                    nval = pre;
                } else {
                    nval = pre + '-' + post;
                }
            } else if (nval.length>4 && !nval.includes('-')){
                const arr = nval.split('');
                const firstthree = arr.splice(0,3);
                const nextthree = arr.splice(0,3);
                nval = firstthree.join('') + '-' + nextthree.join('');
                if (arr.length) nval += '-' + arr.join('');
            }
            val[input] = nval;
        } else {
            val[input] = e.target.value;
        }
        delete err[input];

        // immediate feedback on postals
        // if (input==='postal'){
        //     // at least 3 characters?
        //     if (e.target.value.length>=3){
        //         // get first 3 chars
        //         if (checkValidPostal(e.target.value)===0) {
        //             err[input] = true;
        //             err['notdeliverable'] = true;
        //         }
        //     }
        // }
        
        setValues(val);
        setErrors(err);
    }

    function checkValidPostal(str){
        const thiszip = str.substr(0,3).toUpperCase();
        // console.log('check',thiszip);
        if(!validpostals.includes(thiszip)){
            // console.log('invalid postal!');
            return 0; // hard failure - outside of delivery zone.
        } else if (str.length<6 || str.length>7){
            return 1; // soft failure - invalid format, but first 3 letters check out.
        }
        return 2; // valid
    }
    function checkValidEmail(email){
        return String(email)
            .toLowerCase()
            .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }
    function checkValidPhone(phone){

        return String(phone)
            .match(
                /[0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/
            )
    }

    function validate(){
        // validate fields
        const err = {num:0};
        fields.forEach( (field,index) => {
            // const input = document.getElementById(field.id);
            if (field.required && ( values[field.id]==='' || !values[field.id]) ){ 
                err[field.id] = true;
                err.incomplete = true;
                err.num++;
            } else if (field.type==='email') {
                if (!checkValidEmail(values[field.id])){
                    err[field.id] = true;
                    err.num++;
                } 
            } else if (field.id==='postal'){
                const validpostal = checkValidPostal(values[field.id]);
                if (validpostal===0){
                    err[field.id] = true;
                    err['notdeliverable'] = true;
                    err.num++;
                } else if (validpostal===1){
                    err[field.id] = true;
                    err.num++;
                }
            } else if (field.id==='phone'){
                const validphone = checkValidPhone(values[field.id]);
                if (!validphone){
                    err[field.id] = true;
                    err.num++;
                }
            } else if (field.type==='number'){
                if (values[field.id]<field.min || values[field.id]>field.max) {
                    err[field.id] = true;
                    err.num++;
                }
            }
            // data[field.id] = values[field.id];
        });

        // check birthday
        const today = new Date();
        const bday = new Date(values.bdayyy, values.bdaymm-1, values.bdaydd);
        const age = ( today - bday ) / (365*24*60*60*1000);
        if (age<19) {
            err.underage = true;
            err.bdayyy = true;
            err.bdaymm = true;
            err.bdaydd = true;
        }


        console.log(err);
        setErrors(err);

        return err.num===0;
    }
    async function submit(){
        // disable submit button
        submitBtn.current.disabled = true;

        if (!validate()) {
            // set errors
            return false;
        }

        setWaiting(true);
        const result = await submitEntry(values);
        // console.log(result);
        
        if (result.status===200){
            setWaiting(false);
            navigate('/confirm');
        } else {
            setWaiting(false);
            // set error based on code.
            if (result.status===409) {
                const val = { ...values};
                delete val.timeslot;
                setValues(val);

                const slots = await getAvailableSlots(true); 
                setFreeSlots(slots.map(s=>s.DateTime));
                submitBtn.current.disabled = false;
                setErrors({timeslottaken:true,num:1,timeslot:true});
                // refresh timeslots.
            } else if (result.status===1062) {
                submitBtn.current.disabled = false;
                setErrors({duplicate:true,num:1,phone:true});
            }
            
        }
    }

    function getTimeSlotClass(d,t){
        const dtstring = d+'|'+t;
        const thisclass = [];
        if (dtstring===values.timeslot){
            thisclass.push('selected');
        }
        if (!freeSlots.includes(dtstring)){
            thisclass.push('disabled');
        }
        return thisclass.join(' ');
    }

    useEffect( ()=>{
        async function getSlots(){
            const slots = await getAvailableSlots(); // how to ensure this doesn't get called before api is initialized?
            setFreeSlots(slots.map(s=>s.DateTime));
        }
        getSlots();
    },[])

    return (
        <Tween from={{ y:50, alpha:0, stagger:.2 }}>
        <h1><img src={sixforsixlock} className="six-lock" alt="The Six Flavour Swap logo over 646 spelled out numerically." /></h1>
        <h2>BOOK A SWAP TIME</h2>
        <p>Select a time slot for your Flavour Swap.<br/>Swaps are only available in certain Toronto neighbourhoods.*</p>
        {dayslots.map(d=>(<div className="date-select-row">
            <h4>{d}</h4>
            {timeslots.map(t=>(<button onClick={handleTimeSlotSelect} id={`${d}|${t}`} className={getTimeSlotClass(d,t)}>{t}</button>))}
        </div>))}
        <span className="error mb-4">{errors.timeslot && <>Please select a window.</>}</span>
        <div className="form-row">
            <small>*Required Fields</small>
            {fields.filter( el=>el.type==='text' || el.type==='email' ).map( (el,i) => (
            <input type="text" key={i+'_'+el.id} placeholder={`${el.required?'*':''}${el.label}`} id={el.id} value={el.value || values[el.id] } onChange={handleInputChange(el.id)} className={errors[el.id]?'error':''} />
            ))}
        </div>
        <div className="birthday-row">
            <h4>Date of birth</h4>
            {fields.filter( el=>el.type==='number' ).map( (el,i) => (
            <input type="number" key={i+'_'+el.id} placeholder={`*${el.label}`} id={el.id} value={values[el.id]} onChange={handleInputChange(el.id)} min={el.min} max={el.max} className={errors[el.id]?'error':''} />
            ))}
        </div>
        <div className="optin-row">
            {fields.filter( el=>el.type==='checkbox' ).map( (el,i) => ( <div>
                <input type="checkbox" key={i+'_'+el.id} id={el.id} min={el.min} max={el.max} checked={values[el.id]} onChange={handleInputChange(el.id)} />
                <label htmlFor={el.id} className={errors[el.id]?'error':''} dangerouslySetInnerHTML={{__html: el.label}} />
                {errors[el.id] && <span className="error">{el.errorMessage}</span>}
            </div>))}
            <input type="hidden" id="timeslot" value={values.timeslot} />
        </div>

        <button onClick={submit} ref={submitBtn}>Submit</button>
        
        <div class="error-row">
            {errors.notdeliverable && <span className="error">Unfortunately, we can’t deliver to that area. If you’re out of our swap zone, you can still get our 6 flavours in 1 convenient 12 pack in the NÜTRL Vodka Soda Mixed Pack. Available at the <a href="https://www.lcbo.com/en/nutrl-the-mixed-12pk-24407" target="_blank">LCBO</a>.</span>}
            {errors.incomplete && <span className="error">Please enter all required fields.</span>}
            {errors.email && <span className="error">Please enter a valid email address.</span>}
            {errors.phone && !errors.duplicate && <span className="error">Please enter a valid phone number.</span>}
            {errors.underage && <span className="error">Sorry, you must be legal drinking age to participate.</span>}
            {errors.timeslottaken && <span className="error">Sorry, that time slot is no longer available. Please select another one to continue.</span>}
            {errors.duplicate && <span className="error">That number has already been used to book a swap.</span>}
        </div>

        </Tween>
    );
}