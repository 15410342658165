import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom'; 
import { unstable_HistoryRouter as HistoryRouter, useLocation, Navigate } from "react-router-dom";
import { Splash, Landing, Form, Confirm, Closed, Terms, Privacy } from './components/pages';
import ReactGA from 'react-ga4';
import Header from './components/Header';
import Footer from './components/Footer';
import { createBrowserHistory } from 'history';
// import CookieConsent from 'react-cookie-consent';
import { Cookies } from "react-cookie-consent";
import { init } from './components/Model';
import ScrollToTop from './components/ScrollToTop';

import './App.scss';

// const GAID = 'G-6KFSQZ5NSE';
// ReactGA.initialize(GAID);

const history = createBrowserHistory({window});
history.listen(({action,location}) => {
    // console.log("tracking page view: ",location.pathname);
    // ReactGA.send({hitType:'pageview',page:location.pathname});
});

function App() {

  const [ ofAge, setOfAge ] = useState(Cookies.get('ofage')==='true');

  useEffect( ()=>{
    const apiPath = 'https://0773d82569eb.ngrok.app/'; // BACKEND_URL;
    init(apiPath,'');
  },[]);

  return (
    <HistoryRouter history={history}>
      <AppWrap>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route index element={<Splash updateAge={setOfAge} />} />
          <Route exact path="/landing" element={ofAge?<Landing />:<Navigate to='/' />} />
          <Route exact path="/form" element={ofAge?<Form />:<Navigate to='/' />} />
          <Route exact path="/confirm" element={ofAge?<Confirm />:<Navigate to='/' />} />
          <Route exact path="/closed" element={ofAge?<Closed />:<Navigate to='/' />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/privacy" element={<Privacy />} />
        </Routes>
        <Footer />
        {/* <CookieConsent style={{ width: 'auto', bottom: '1rem', left: '1rem', right: '1rem', textAlign: 'left' }}
          buttonText="Accept" buttonStyle={{ color: '#fff', backgroundColor: '#000' }} 
          overlay={true} overlayStyle={{ maxWidth:'unset'}} >
              <p>Legal copy for all cookies goes here. 
              </p>
        </CookieConsent> */}
      </AppWrap>
    </HistoryRouter>
  );
}

function AppWrap (props){
  const location = useLocation();
  const [ isSplash, setIsSplash ] = useState(false);

  useEffect(()=>{
      const path = location.pathname;
      setIsSplash( path==='/' );
  },[location]);

  return (
    <div className={`App ${isSplash?'dark':''}`}>
      { props.children }
    </div>
  )
}

export default App;
