import { useNavigate, Link } from 'react-router-dom';
import { Tween } from 'react-gsap';

import sixforsixlock from '../../assets/646 Lockup.png';
import fb from '../../assets/Facebook Icon.png';
import tw from '../../assets/Twitter Icon.png';
import ig from '../../assets/Instagram Icon.png';

export default function Closed({}){
    const navigate = useNavigate();

    const exit = () => {
        window.open('https://www.lcbo.com/en/nutrl-the-mixed-12pk-24407');
    }

    return (
        <Tween from={{ y:50, alpha:0, stagger:.2 }}>
            <h1><img src={sixforsixlock} className="six-lock" alt="The Six Flavour Swap logo over 646 spelled out numerically." /></h1>
            <h2>WE’RE ALL SWAPPED OUT</h2>
            <p className="narrow">Unfortunately, there are no more available swap times. You can still find 6 delicious flavours of NÜTRL Vodka Soda in our Mixed 12 Pack, available at your nearest LCBO.</p>
            <button onClick={exit}>Visit LCBO</button>
            <div className="social-row">
                <a href="https://www.facebook.com/nutrlvodka" target="_blank"><img src={fb} alt="Facebook" /></a>
                <a href="https://www.instagram.com/nutrlvodka/" target="_blank"><img src={ig} alt="Instagram" /></a>
                <a href="https://twitter.com/nutrlcanada" target="_blank"><img src={tw} alt="Twitter" /></a>
            </div>
        </Tween>
    );
}