import { Tween } from 'react-gsap';
import { Link } from 'react-router-dom';
import logo from '../assets/Nutrl Logo.png';

export default function Header(){
    return (
        <Tween from={{ y:50, alpha:0, stagger:.2 }}>
        <header>
            <Link to="/landing"><img src={logo} className='Header-Logo' /></Link>
        </header>
        </Tween>
    );
}