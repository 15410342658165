import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tween } from 'react-gsap';
// import Select from 'react-select';
import { Cookies } from "react-cookie-consent";

import sixforsix from '../../assets/646.png';

export default function Splash({updateAge}){
    const navigate = useNavigate();
    
    const [ errors, setErrors ] = useState({});

    function bouncer(){
        Cookies.set('ofage', true, {expires:1});
        updateAge(true);
        navigate('/landing');
    }
    function bounceout(){
        Cookies.set('ofage',false, {expires:1});
        window.open('https://www.familytalkaboutdrinking.com/');
        setErrors({underage:true});
    }
    function unbounce(){
        Cookies.remove('ofage');
        setErrors({});
    }
    
    useEffect( ()=>{
        const ofage = Cookies.get('ofage');
        // console.log('ofage',ofage);
        if (ofage==='false'){
            console.log('go');
            setErrors({underage:true});
        } else if (ofage==='true'){
            navigate('/landing');
        }
    },[]);


    return (
        <>
        <Tween from={{ y:50, alpha:0, delay:.5 }} wrapper={<h1/>}>
        <img src={sixforsix} className="six-lock" alt="The Six Flavour Swap logo over 646 spelled out numerically." />
        </Tween>
        <div className="age-gate">
            
            {errors.underage 
                ? <Tween from={{ y:50, alpha:0, delay:1, stagger:.2 }}>
                <span>Sorry, you must be of legal drinking age to enter this site.</span>
                <div className="age-gate-row">
                    <button onClick={unbounce}>Back</button>
                </div>
            </Tween>
                : <Tween from={{ y:50, alpha:0, delay:1, stagger:.2 }}>
                <h3>ARE YOU OVER THE LEGAL DRINKING AGE OF 19+?</h3>
                <div className="age-gate-row">
                    <button onClick={bouncer}>YES<span>I am of legal drinking age</span></button>
                    <button onClick={bounceout}>NO<span>I am not of legal drinking age</span></button>
                </div>
            </Tween>
            }

        </div>
        </>
    );
}